import * as React from "react"
import {graphql} from "gatsby"


import { GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image'


import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data, location }) => (
  <Layout>
    <SEO 
        pagetitle="Maipについて"
        pagedesc="各種SNSのニュースを配信していきます"
        pagepath={location.pathname}
        pageimg={data.about.childImageSharp.original.src}
        pageimgw={data.about.childImageSharp.original.width}
        pageimgh={data.about.childImageSharp.original.height}
    />
    <div>

    <section className="hero">
        <figure>
        <StaticImage
              style={{ height: "100%" }}
              style={{ width: "100%" }}
            src="../images/fun.jpg"
            alt="fun"
            />
        </figure>
      <div className="catch">
        <h1>About MaiP</h1>
      </div>
      <div className="wave">
        <img src="../images/wave.svg" alt="" />
      </div>
    </section>
        <article className="content">
          <div className="container">
            <h1 className="bar">MaiPについて</h1>
            {/* <aside className="info">
              <div className="subtitle">
                <i className="fas fa-utensils" />
                ABOUT ESSENTIALS
              </div>
            </aside> */}
            <div className="postbody">
              <p>SNS関連のニュースを配信するサイトです。ひとまずはTikTokに絞って配信します。需要があれば、他のSNSもやっていこうと思っています!</p>
              <h1 className="bar">新しいクリエイターを紹介したい</h1>
              <p>管理人の思いとして、まだあまり名前の知られていないクリエイターを紹介したいと思っています。そのため、基本的に変動率(フォロワー数増加率など)に注目したサイト構成にしています。</p>
              <h1 className="bar">統計情報</h1>
              <p>アカウントの統計情報は、当面は日時で更新します。アカウントごとに表示されている特徴語は、投稿されているコンテンツの情報などから、抽出しています。現在は、「フォロワー数増加率(5日間)」だけですが、今後さらに拡張していく予定です！</p>
              <h1 className="bar">お問い合わせ</h1>
              <p>何かお問い合わせなどあれば、TwitterのDMまでお願いいたします。</p>
             
{/*               
              <ul>
                <li>野田市の飲食店レビュー(近所なので)</li>
                <li>ライターの身の回りの出来事</li>
                <li>ポエム</li>
              </ul> */}
              {/* <h2><i className="fas fa-check-square" />よく聞かれること</h2>
              <p>よく聞かれることなのですが、私たちはスタートアップではありません。私たちはまだスタートしたばかりで、より多くの人々が食べやすい食べ物がもたらす違いを発見できるように、成長しサービスを改善する機会を待っています。</p>
              <p>気長にお付き合いいただければ幸いです。</p> */}
            </div>
          </div>
        </article>
      </div>
  </Layout>
)


export const query = graphql`
query {
  about: file(relativePath: { eq: "fun.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1200) {
        ...GatsbyImageSharpFluid_withWebp
      }
      original{
          src
          height
          width
      }
    }
  }
}
`
